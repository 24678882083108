export const shortifyConvoTitle =(chat)=>{
    const convo = JSON.parse(chat)
    const title = convo[0].user
    if(title.length>23){
      return `${title.slice(0, 22)}.."`
    }else{
      return title
    }

  }
export const shortifyConverseTitle =(chat)=>{
    // const convo = JSON.parse(chat)
    const title = chat
    if(title.length>20){
      return `${title.slice(0, 18)}...`
    }else{
      return title
    }

  }