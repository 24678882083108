import { Box, HStack, IconButton, Text, Textarea, VStack } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import SectionContainer from "./SectionContainer";
import { BsImage, BsMic, BsSend } from "react-icons/bs";
import axios from "axios";

import { Cloudinary } from "@cloudinary/url-gen";
import { auto } from "@cloudinary/url-gen/actions/resize";
import { autoGravity } from "@cloudinary/url-gen/qualifiers/gravity";

const ChatFooter = (props) => {
	// const [image, setImage] = useState(null);
	// const [preview, setPreview] = useState("");
	// const [uploadedUrl, setUploadedUrl] = useState("");
	// const [uploadingImage, setUploadingImage] = useState(false)
	const fileInputRef = useRef(null);

	const CLOUD_NAME = "didwcc2e7";
	const UPLOAD_PRESET = "ml_default";

	const cld = new Cloudinary({ cloud: { cloudName: "didwcc2e7" } })

	const img = cld.image("cld-sample-5").format("auto").quality("auto").resize(auto().gravity(autoGravity()).width(500).height(500));

	const handleIconClick = () => {
		fileInputRef.current.click();
	};

	return (
		<Box margin={0} width={"full"} bg={"#FCFCFC"} color={"#1B4B1D"}>
			<VStack width={"full"} p={"20px"}>
				{" "}
				<HStack
					width={"full"}
					p={"0 12px"}
					border={"1px solid #E6E5E5"}
					justifyContent={"space-between"}
					borderRadius={"52px"}
					alignItems={"center"}
					borderColor={"#1B4B1D"}
				>
					{props.preview !== "" &&
						<img className={`w-[20px] h-[20px] ${props.uploadingImage ? "opacity-25" : "opacity-100"}`} src={props.preview} alt="question image" />
					}
					<IconButton aria-label="Upload Image"
						icon={<BsImage fontSize={"22px"} />}
						onClick={handleIconClick}
						variant="ghost"
					/>
					<input
						type="file"
						accept="image/*"
						ref={fileInputRef}
						style={{ display: "none" }}
						onChange={props.handleFileChange}
					/>

					{/* <BsImage fontSize={"22px"} /> */}
					<BsMic fontSize={"22px"} />
					<Textarea
						minH={"30px"}
						placeholder="Ask me anything"
						width={"90%"}
						resize={"none"}
						border={"none"}
						outline={"none"}
						color={props.aiLoading ? "gray.300" : "black"}
						readOnly={props.aiLoading}
						_focusVisible={{ outline: "none" }}
						onChange={props.writeMessage}
						value={props.message}
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								e.preventDefault()
								props.sendMessage();
								props.setInit(false);
							}
						}}
					/>

					<button
						disabled={props.aiLoading || props.uploadingImage}
						onClick={() => {
							props.sendMessage();
							props.setInit(false);
						}}
					>
						<BsSend
							fontSize={"22px"}
						/>
					</button>
				</HStack>
				<Text textAlign={"center"} fontSize={["xs", "xs", "sm"]}>
					QuidX is an experimental product which may occasionally produce
					inaccurate or misleading results.
				</Text>
			</VStack>
		</Box>
	);
};

export default ChatFooter;
