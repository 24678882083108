import React from "react";

const ChatCard = ({ role, content, uploadedUrl }) => {
  return (
    <div
      className={`w-full py-5 px-4 ${role === "user" ? "bg-white" : "bg-green-100"
        }`}
    >
      <div
        className={`max-w-4xl flex items-start gap-4 responseInfo ${role === "user" ? "ml-auto md:w-6/12 w-10/12" : "w-full"
          }`}
      >
        {/* User Icon Box */}
        <div className="flex-shrink-0 flex items-center justify-center w-10 h-10 bg-green-800 text-white font-bold rounded-md">
          {role === "user" ? "Q" : "A"}
        </div>

        {/* Message Content */}
        <div className="flex flex-col w-full">
          <div
            className="mt-2 text-gray-800 text-lg leading-relaxed"
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default ChatCard;
