import { HStack, Box, VStack, Toast, useDisclosure } from "@chakra-ui/react";
import React, { useState, useContext, useEffect } from "react";
import ChatSidebar from "./ChatSidebar";
import ChatFooter from "./ChatFooter";
// import { ChatContext } from "../pages/Chat";
import { ChatContext } from "../pages/Admin/TestChat";
import axios from "axios";
import DashNav from "./DashNav";
import { HiMenuAlt3 } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

import { Cloudinary } from "@cloudinary/url-gen";
import { auto } from "@cloudinary/url-gen/actions/resize";
import { autoGravity } from "@cloudinary/url-gen/qualifiers/gravity";

import MobileChatSideBar from "./MobileChatSideBar";
import Draggable from "react-draggable";

const ChatLayout = (props) => {
	const { chats, setChats, aiLoading, setAiLoading, chatId, setChatId } =
		useContext(ChatContext);
	const navigate = useNavigate();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [uid, setuid] = useState(localStorage.getItem("userId"))
	const [message, setMessage] = useState();
	const [dataMessage, seDataMessage] = useState({})
	const [init, setInit] = useState(true);
	const [creditScore, setCreditScore] = useState(null)
	const [image, setImage] = useState(null);
    const [preview, setPreview] = useState("");
    const [uploadedUrl, setUploadedUrl] = useState("");
	const [uploadingImage, setUploadingImage] = useState(false)

	const CLOUD_NAME = "didwcc2e7";
    const UPLOAD_PRESET = "ml_default";

	const cld = new Cloudinary({ cloud: { cloudName: "didwcc2e7" } })

    const img = cld.image("cld-sample-5").format("auto").quality("auto").resize(auto().gravity(autoGravity()).width(500).height(500));

	const handleFileChange = (e) => {
        const file = e.target.files[0];
        setImage(file);
        setPreview(URL.createObjectURL(file));
    };
	const handleUpload = async () => {
        if (!image) {
            alert("Please select an image first");
            return;
        }
		setUploadingImage(true)

        const formData = new FormData();
        formData.append("file", image);
        formData.append("upload_preset", UPLOAD_PRESET); // Required by Cloudinary

        try {
            const response = await axios.post(
                `https://api.cloudinary.com/v1_1/${CLOUD_NAME}/image/upload`,
                formData
            );

            setUploadedUrl(response.data.secure_url);
			setUploadingImage(false)
			// Toast({ title: "An error occurred", status: "success" });
        } catch (error) {
            console.error("Error uploading image:", error);
            alert("Failed to upload image");
			setUploadingImage(false)
        }
    }
	const writeMessage = (e) => {
		setMessage(e.target.value);
	};
	
	useEffect(() => {
		if (preview) {
			handleUpload();
		}
	}, [preview]);

	const sendMessage = async () => {
		if (!chatId) {
			try {
				setAiLoading(true);
				await axios
					.post(
						`${process.env.REACT_APP_TEST_BASE_URL}chat`,
						{
							message: message || "Help me analyze this image with your AI model",
							user_id: uid,
							image: uploadedUrl,
						},
						{
							headers: {
								Authorization: `Bearer ${localStorage.getItem("quidsToken")}`,
							},
						}
					)
					.then((response) => {
						let aiResponse = response.data.data['quidx-ai'];
						setAiLoading(false);
						// setChats([...chats, { user: {message:message, user_img: uploadedUrl}, "quidx-ai": aiResponse }]);
						setChats([...chats, { user: response.data.data.user, "quidx-ai": aiResponse }]);
						setChatId(response.data.session_id);
						navigate(`/chat/${response.data.session_id}`);
						setMessage("");
						setPreview("");
						setCreditScore(response.data.available_credit)
					})
					.catch((error) => {
						setAiLoading(false);
						const statusCode = error.response.data.statusCode;

						if (statusCode === 401) {
							Toast({
								title: "Unauthorized access. Please log in to continue.",
								status: "warning",
							});
							navigate("/signin");
						} else {
							Toast({ title: "An error occurred", status: "warning" });
						}
					});
			} catch (error) {
				Toast({ title: "An error occurred", status: "warning" });
			}
		} else {
			try {
				setAiLoading(true);
				await axios
					.post(
						`${process.env.REACT_APP_TEST_BASE_URL}chat`,
						{
							message: message || "Help me analyze this image with your AI model",
							user_id: uid,
							session_id: chatId,
							image: uploadedUrl,
						},
						{
							headers: {
								Authorization: `Bearer ${localStorage.getItem("quidsToken")}`,
							},
						}
					)
					.then((response) => {
						// let aiResponse = response.data.data;
						let aiResponse = response.data.data['quidx-ai'];
						setAiLoading(false);
						// setChats([...chats, { user: {message:message, user_img: uploadedUrl}, "quidx-ai": aiResponse }]);
						setChats([...chats, { user: response.data.data.user, "quidx-ai": aiResponse }]);
						setMessage("");
						setPreview("");
						setCreditScore(response.data.available_credit)
					})
					.catch((error) => {
						setAiLoading(false);
						const statusCode = error.response.data.statusCode;

						if (statusCode === 401) {
							Toast({
								title: "Unauthorized access. Please log in to continue.",
								status: "warning",
							});
							navigate("/signin");
						} else {
							Toast({ title: "An error occurred", status: "warning" });
						}
					});
			} catch (error) {
				Toast({ title: "An error occurred", status: "warning" });
			}
		}
	};

	return (
		<VStack padding={"4px"} width={"full"} height={"100vh"} bg={"#1B4B1D"}>

			<HStack
				width={"full"}
				spacing={2}
				height={"100%"}
				alignItems={"flex-start"}
				bg={"#1B4B1D"}
			>
				<VStack
					flex={1}
					width={"20%"}
					height={"full"}
					overflowY={"hidden"}
					bg={"#D3DCD1"}
					borderRadius={"20px"}
					display={["none", "none", "block"]}
				>
					<ChatSidebar />
					<MobileChatSideBar />
				</VStack>

				<VStack
					className="m-auto"
					alignItems={"flex-start"}
					padding={0}
					width={"full"}
					height={"full"}
					overflowY={"hidden"}
					flex={3}
					bg={"#F8FCF8"}
					borderRadius={"10px"}
					position={"relative"}
					left={"-4px"}
				>
					<Box width={"full"} display={["none", "none", "block"]}>
						<DashNav creditScore={creditScore ? creditScore : 0} />
					</Box>
					<Box
						width={"full"}
						position={"sicky"}
						display={["block", "block", "none"]}
						top={0}
						right={5}
					>
						<HStack width={"full"} justifyContent={"flex-end"}>
							<HiMenuAlt3
								fontSize={"30px"}
								onClick={() => {
									onOpen();
								}}
							/>
						</HStack>
					</Box>
					<Box width={"full"} height={"90%"} overflowY={"scroll"}>
						{props.children}
					</Box>
					{/* {creditScore &&
						<Draggable>
							<div className="text-white text-center bg-[var(--primary-color)] fixed rounded-full px-2 py-5 left-[20px]">
								<span className="text-[12px] block">{creditScore}</span>
								<small className="text-[8px]"><b>Available Credit</b></small>
							</div>
						</Draggable>
					} */}
					<Box width={"full"} position={"sticky"} bottom={0}>
						<ChatFooter
							aiLoading={aiLoading}
							setInit={setInit}
							message={message}
							writeMessage={writeMessage}
							sendMessage={() => sendMessage()}
							preview ={preview}
							uploadingImage = {uploadingImage}
							handleFileChange = {handleFileChange}
						/>
					</Box>
				</VStack>
				<MobileChatSideBar onClose={onClose} isOpen={isOpen} />
			</HStack>
		</VStack>
	);
};

export default ChatLayout;
