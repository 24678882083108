import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion';

const GoogleAuthToken = () => {
    const navigate = useNavigate();
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const token = params.get("token");
        if (token) {
            localStorage.setItem('quidsToken', token);
            axios.get(`${process.env.REACT_APP_BASE_URL}/users/profile`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                navigate("/dashboard");
            }).catch((err) => {
                navigate("/google-auth/error");
            })
        }
    }, [])
    return (
        <>
            <div className="flex flex-col items-center justify-center min-h-screen bg-gray-400">
                {/* Animated Logo */}
                <motion.img
                    src="/assets/images/quidsailogo.png"
                    alt="Authenticating..."
                    className="w-100 h-24 mb-4"
                    animate={{ scale: [1, 1.2, 1] }}
                    transition={{
                        duration: 1.5,
                        repeat: Infinity,
                        ease: "easeInOut",
                    }}
                />

                {/* Authentication Text */}
                <p className="text-[var(--primary-color)] text-black font-medium">Authenticating...</p>
            </div>
        </>
    )
}

export default GoogleAuthToken