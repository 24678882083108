import {
	Image,
	VStack,
	Box,
	HStack,
	Text,
	useToast,
	Drawer,
	DrawerOverlay,
	DrawerHeader,
	DrawerCloseButton,
	DrawerBody,
	DrawerContent,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import SectionContainer from "./SectionContainer";
import { BsArrowRight, BsChatLeft, BsPerson, BsPlus } from "react-icons/bs";
import { MdHome, MdMenuBook, MdSettings } from "react-icons/md";
import { FaEllipsisH } from "react-icons/fa";
import axios from "axios";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
// import { ChatContext } from "../pages/Chat";
import { ChatContext } from "../pages/Admin/TestChat";
import { shortifyConverseTitle, shortifyConvoTitle } from "../utils/utils";

const MobileChatSideBar = (props) => {
	const { chatId, setChats, setChatId } = useContext(ChatContext);
	const params = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const toast = useToast();
	const [loading, setLoading] = useState(true);

	const [chatHistory, setChatHistory] = useState([]);
	const fetchConversations = async () => {
		await axios
			.get(
				`${process.env.REACT_APP_TEST_BASE_URL
				}conversations/${localStorage.getItem("userId")}`,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem("quidsToken")}`,
					},
				}
			)
			.then((response) => {
				if (response.data.message == "No conversations found for the user"){
					setLoading(false)
					setChatHistory([])
					return
				}
				const transformedArray = Object.entries(response.data).map(([key, value]) => ({
					id: key,
					...value,
				  }));
				  setChatHistory(transformedArray);
			})
			.catch((error) => {
				// alert(error)
				if(error == "AxiosError: Network Error"){
					toast({
						title: "Network Error, Please check your network connection",
						status: "warning",
					});
					setLoading(false)
					setChatHistory([])
					return
				}

				const statusCode = error?.response?.data.statusCode;
				if (statusCode === 401) {
					toast({
						title: "Unauthorized access. Please log in to continue.",
						status: "warning",
					});
					navigate("/signin");
				} else {
					toast({ title: "An error occurred", status: "warning" });
					
				}
					setLoading(false)
					setChatHistory([])
					return
			});
	};

	const fetchId = async () => {
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_BASE_URL}/chat/`,
				{},
				{
					headers: {
						authorization: `Bearer ${localStorage.getItem("quidsToken")}`,
					},
				}
			);
			const tempId = response.data.data.id;
			setChatId(tempId);
			navigate(`/chat/${tempId}`);
			props.onClose();
		} catch (error) {
			const statusCode = error.response.data.statusCode;

			if (statusCode === 401) {
				toast({
					title: "Unauthorized access. Please log in to continue.",
					status: "warning",
				});
				navigate("/signin");
			} else {
				toast({ title: "An error occurred", status: "warning" });
			}

			// Handle the error here, if needed
		}
	};

	const appendPath = (path) => {
		const currentPath = location.pathname;
		let newPath;
		if (chatId) {
			const paths = currentPath.split("/");

			newPath = `${paths[0]}/${paths[1]}/${path}`;
			navigate(newPath);
		} else {
			newPath = `${currentPath}/${path}`;
			navigate(newPath);
		}
	};

	useEffect(() => {
		fetchConversations();
	}, []);
	return (
		<Drawer
			isOpen={props.isOpen}
			size={"xs"}
			placement={"left"}
			onClose={props.onClose}
		>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerHeader padding={0}>
					<DrawerCloseButton />
				</DrawerHeader>
				<DrawerBody padding={0}>
					<VStack
						width="full"
						height={"full"}
						display={["flex", "flex", "none"]}
						position={"relative"}
						bg={"#1B4B1D"}
					>
						<SectionContainer>
							<VStack width={"full"} gap={5}>
								<Image src="/assets/images/quidsailogo.png" width={"66px"} />
								<VStack
									width={"full"}
									height={"full"}
									gap={5}
								>
									<HStack
										width={"full"}
										color={"#FCFCFC"}
										alignItems={"center"}
										padding={2}
										border={"0.5px solid #FCFCFC"}
										borderRadius={"4px"}
										cursor={"pointer"}
										onClick={() => {
											setChats([]);
											setChatId("");
											navigate("/chat");
											props.onClose();
										}}
									>
										<BsPlus fontSize={"30px"} cursor={"pointer"} />
										<Text fontSize={"14px"}>New Chat</Text>
									</HStack>
									<VStack overflowY={"auto"} height={"417px"} width={"full"} gap={2}>
										{chatHistory.length > 0 ? (
											chatHistory
												.map((chat) => {
													return (
														<HStack
															className={`p-2 rounded-md ${params.sessionId === chat.id ? 'bg-[var(--primary-color)]': ''}`}
															width={"full"}
															color={"#FCFCFC"}
															alignItems={"center"}
															cursor={"pointer"}
															key={chat.id}
															onClick={() => {
																setChatId(chat.id);
																navigate(`/chat/${chat.id}`);
																props.onClose();
															}}
														>
															<BsChatLeft fontSize={"24px"} />
															<Text fontSize={"16px"} color={"#FCFCFC"}>
																{shortifyConverseTitle(chat.messages[0].user_input)}
															</Text>
														</HStack>
													);
												})
										) : (
											<Text fontSize={"16px"} color={"#FCFCFC"}>
												No chat history
											</Text>
										)}
									</VStack>
								</VStack>
							</VStack>
						</SectionContainer>
						<VStack
							width={"full"}
							bg={"#ECF8ED"}
							position={"absolute"}
							bottom={0}
						>
							<SectionContainer>
								<VStack width={"full"} gap={1}>
									<VStack width={"full"} alignItems={"flex-start"}>
										<Link to={"/"}>
											<HStack width={"full"} justifyContent={"flex-start"}>
												<HStack gap={2}>
													<MdHome fontSize={"28px"} />
													<Text>Home</Text>
												</HStack>
											</HStack>
										</Link>
									</VStack>

									<HStack width={"full"} justifyContent={"space-between"}>
										<HStack gap={2}>
											<MdSettings fontSize={"28px"} />
											<Link to={"/pricing"}>Upgrade Plan</Link>
										</HStack>
										<Box
											position={"relative"}
											transition={" 0.3s  "}
											_hover={{ position: "absolute", right: 5 }}
											cursor={"pointer"}
										>
											<BsArrowRight fontSize={"24px"} />
										</Box>
									</HStack>
									<HStack width={"full"} justifyContent={"space-between"}>
										<HStack gap={2}>
											<BsPerson fontSize={"28px"} />

											<Text>Account Name</Text>
										</HStack>
										<Box
											position={"relative"}
											transition={"position 5s  "}
											_hover={{ position: "absolute", right: 5 }}
											cursor={"pointer"}
										>
											<FaEllipsisH fontSize={"24px"} letterSpacing={"200px"} />
										</Box>
									</HStack>
								</VStack>
							</SectionContainer>
						</VStack>
					</VStack>
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
};

export default MobileChatSideBar;
