import React, { useEffect, useState, createContext, useRef } from "react";
import { HStack, Text, VStack, Spinner, useToast } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { MdMessage } from "react-icons/md";
import axios from "axios";
import ChatLayout from "../../components/ChatLayout";
// import ChatLayout from "../../components/ChatLayout";
import ChatCard from "../../components/ChatCard";

export const ChatContext = createContext();

const TestChat = () => {
    const navigate = useNavigate();
    const toast = useToast();
    const params = useParams();
    const ref = useRef();
    const [chats, setChats] = useState([]);
    const [aiLoading, setAiLoading] = useState(false);
    const [chatId, setChatId] = useState(params.sessionId ? params.sessionId : "");
    const [toggleHistory, setToggleHistory] = useState(false);

    const fetchChats = async () => {
        try {
            // Make the API request
            const response = await axios.get(
                `${process.env.REACT_APP_TEST_BASE_URL
                }conversations/${localStorage.getItem("userId")}`
            );

            // Log the response

            // Parse and set the chats
            // const parsedMessage = JSON.parse(response.data[0].message);
            // setChats(parsedMessage);
        } catch (error) {
            // Handle errors and show a warning toast
            // console.error("Error fetching chats:", error);
            toast({
                title: "An error occurred while fetching chats",
                status: "warning",
            });
        }
    };

    const fetchSingleChat = async (chatId) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_TEST_BASE_URL
                }conversations_session_id/${chatId}`
            );
            // const filteredMessages = response.data.data.filter(
            //     (msg) => !msg.user.includes("Help me analyze this image")
            //   );
            if (response.data.status == "error") {
                setChats([]);
                alert("No chat session found");
                toast({
                    title: "An error occurred while processing your request",
                    status: "warning",
                });
                return;
            }
            if(response.data.data.length > 0){
            setChats(response.data.data)
            }
        } catch (error) {
            // console.error("Error fetching chats:", error);
            toast({
                title: "An error occurred while fetching chats",
                status: "warning",
            });
        }
    }

    useEffect(() => {
        if (params.sessionId) {
            fetchSingleChat(chatId);

        } else {
            fetchChats()
        }
    }, [params]);

    useEffect(() => {
        if (localStorage.getItem("quidsToken") == null) {
            navigate("/signin");
            return;
        }
        if (chats.length) {
            ref.current?.scrollIntoView({
                behavior: "smooth",
                block: "end",
            });
        }
        console.log("Chats:", chats);
    }, [chats.length, aiLoading]);
    return (
        <>
            <ChatContext.Provider
                value={{
                    chats,
                    setChats,
                    aiLoading,
                    setAiLoading,
                    setChatId,
                    chatId,
                    toggleHistory,
                    setToggleHistory,
                }}
            >
                <ChatLayout>
                    <VStack width={"full"} height={"full"} overflowY={"scroll"}>
                        {!params.sessionId && (
                            <HStack display={["flex", "flex", "flex"]}>
                                <Text>Start New Chat</Text>
                                <MdMessage />
                            </HStack>
                        )}

                        {chats ? (
                            chats?.length > 0 ? (
                                chats?.map((chat, id) => {
                                    const role = id % 2;
                                    if (chat?.user != null || chat?.user != undefined) {
                                       
                                    return (
                                            <div key={id}>
                                                <ChatCard
                                                    key={`${id}a`}
                                                    role={"user"}
                                                    content={chat?.user.replace("Help me analyze this image with your AI model", "")}
                                                />
                                                <ChatCard
                                                    key={`${id}b`}
                                                    role={"quidx-ai"}
                                                    content={chat["quidx-ai"]}
                                                />
                                            </div>
                                    );
                                    }
                                })
                            ) : (
                                ""
                            )
                        ) : (
                            <Text>No chat session</Text>
                        )}
                        {aiLoading ? (
                            <div className="w-full flex flex-col items-center space-y-2">
                                {/* Simulating text loading */}
                                <div className="w-1/3 h-4 bg-gray-300 rounded ml-auto animate-pulse"></div>
                                <div className="w-1/3 h-4 bg-gray-300 rounded ml-auto animate-pulse"></div>
                                <div className="w-1/2 h-4 bg-gray-300 rounded ml-auto animate-pulse"></div>

                                {/* Simulating a response box */}
                                <div className="w-3/4 h-20 bg-gray-200 rounded-lg ml-auto animate-pulse"></div>
                            </div>
                        ) : (
                            ""
                        )}
                        <div ref={ref} />
                    </VStack>
                </ChatLayout>
            </ChatContext.Provider>
        </>
    )
}

export default TestChat