// import React from "react";
import ThemeLayout from "../components/ThemeLayout";
// import { HStack, Text, VStack, Input, Button } from "@chakra-ui/react";
// import PricingCard from "../components/PricingCard";
// import SectionContainer from "../components/SectionContainer";
import Header from "../components/Header";
import Navbar from "../components/Navbar";

import React, { useState } from 'react'
import { useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Plans = () => {
  const navigate = useNavigate();
  const [plan, setPlan] = useState([]);
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/payment/packages`).then((res) => {
      if (res.data.data) {
        setPlan(res.data.data)
        res.data.data[0].features = [
          "Access to ChatX for fundamental trading queries.",
          "Basic market insights and news alerts.",
          "Limited trading signals (e.g., 10 signals/month).",
          "Community access."
        ]
        res.data.data[1].features = [
          "All Basic features.",
          "Personalized trading signals (e.g., 20 signals/month).",
          "Basic chart interpretation models.",
          "Access to Risk Calculator.",
          "Priority support."
        ]
        res.data.data[2].features = [
          "All Standard features.",
          "Unlimited trading signals.",
          "Access to JournalX.",
          "Early access to new features.",
        ]
      }
    }).catch((err) => {
      console.log(err);
    })
  }, [])
  const makePayment = (id) => {
    let data = {
      redirectUrl: `https://quidxai.com/success-payment`,
      packageId: id,
      currency: "NGN"
    }
    axios.post(`${process.env.REACT_APP_BASE_URL}/payment/paystack/initiate`, data,{
      headers: {
        Authorization: `Bearer ${localStorage.getItem("quidsToken")}`,
      },
    }
    ).then((res)=>{
      window.location.href = res.data.data.paymentUrl
    }).catch((err)=>{
      if(err.response.data.statusCode == 401){
        alert(err.response.data.message + "\n Please login");
        navigate("/signin");
      }
    })
  }
  return (
    <>
      <ThemeLayout>
        <Header showLogo={true} />
        <div className="text-center bg-[var(--primary-color)]">
          {/* <Header showLogo={true} /> */}
          {/* <Navbar /> */}

          <div className="bg-gray-100 text-gray-800">
            {/* Our Packages Section */}
            <div className="relative overflow-hidden py-10" style={{ zIndex: 1 }}>
              <div className="absolute w-full lg:h-[90vh] h-full lg:top-[-220px] top-[-40px] inset-0 bg-[var(--primary-color)] transform -skew-y-[8deg]" style={{ zIndex: -1 }}></div>
              {/* <div className="text-center py-10 bg-[var(--primary-color) text-white"> */}
              <h1 className="text-[36px] font-bold">Our Packages</h1>
              <p className="text-[24px] mt-2 lg:px-[530px] md:px-300px] px-0">Choose the Package That Fits Your Trading Goals</p>
              <div className="md:flex block justify-center gap-4 lg:px-[80px] px-5 mt-[50px]">
                {plan && plan.map((el, index) => (
                  <div
                    key={index}
                    className={`bg-[#D3DCD1] lg:w-1/4 w-full my-5 py-8 px-6 rounded-[16px] border-t-[5px] border-t-[#1B4B1D] shadow-lg text-gray-800 skew-y-[7deg] ${el.isCustom ? "transform scale-105" : ""
                      }`}
                  >
                    <div className="skew-y-[-8deg]">
                      <p className="text-[#333333] text-[16px] font-medium">{el.text1}</p>
                      <h1 className="text-[#1B4B1D] text-[48px] font-extrabold my-4">
                        {el.credit}c<small className="text-[16px]">/${Number(el.amount).toLocaleString()}</small>
                      </h1>
                      <div className="h-[200px]">
                        {
                          el.features.map((el, i) => (
                            <p className="text-left flex items-start gap-2 py-1" key={i}>
                              <i className="fa fa-check bg-[var(--primary-color)] text-[14px] text-white p-1 rounded-full block"></i>
                              <span className="block text-[15px] text-gray-600">{el}</span>
                            </p>
                          ))
                        }
                      </div>
                      {/* <p className="text-[16px] text-gray-600 mb-6">{el.text2}</p> */}
                      <button onClick={() => makePayment(el.id)} className="bg-[var(--primary-color)] text-white mt-5 py-2 px-4 rounded-md hover:bg-green-600">
                        Buy Now
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* </div> */}

            {/* How to Purchase Section */}
            <div className="py-10 px-10">
              <h2 className="text-[28px] font-bold text-center mb-6">How to Purchase - A Simple Guide</h2>
              <div className="flex items-center justify-center gap-10 flex-col md:flex-row">
                <div>
                  {[
                    "Select a plan",
                    "⁠Make a Payment via Korapay",
                    "Wait for Confirmation",
                    "Receive your credits"].map((step, i) => (
                      <div key={i} className="flex items-center gap-4 py-3">
                        <div className="bg-[var(--primary-color)] text-white rounded-full w-10 h-10 px-4 flex items-center justify-center font-bold">
                          {i + 1}
                        </div>
                        <p className="text-gray-700 text-[16px]">
                          {step}
                        </p>
                      </div>
                    ))}
                </div>
                <div className="">
                  <img
                    src="/assets/images/pricing_image.png" // Replace with actual image
                    alt="How to Purchase"
                    className="rounded-lg shadow-lg"
                  />
                </div>
              </div>
            </div>

            {/* Stay Informed Section */}
            <div className="bg-[#1B4B1D] py-10 px-2 text-white text-center">
              <h2 className="text-[28px] font-bold">Stay Informed</h2>
              <p className="text-[16px] mb-6">
                Subscribe to the newsletter to hear about Quidx AI updates and events
              </p>
              <form className="flex justify-center max-w-2xl mx-auto border-[2px] border-[#F8FCF8] rounded-md overflow-hidden" >
                <button
                  type="submit"
                  className="bg-[#DAF1DB] text-[var(--primary-color)] font-bold py-4 px-6 hover:bg-gray-200"
                >
                  Join Waitlist
                </button>
                <input
                  type="email"
                  placeholder="Email Address"
                  className="py-4 px-4 flex-1"
                />

              </form>
            </div>
          </div>
        </div>
      </ThemeLayout>
    </>
  )
}

export default Plans