import { EmailIcon, LockIcon } from "@chakra-ui/icons";
import {
  Box,
  Checkbox,
  HStack,
  Image,
  Text,
  VStack,
  useToast,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import AuthButton from "../components/AuthButton";
import AuthInput from "../components/AuthInput";
import { TbEye, TbEyeClosed } from "react-icons/tb";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useEffect, useState } from "react";
import { MdAccountCircle } from "react-icons/md";

const Signup = () => {
  // const currentUrl = window.location.origin + "/verify";
  const currentUrl = process.env.REACT_APP_BASE_URL + "/verify";

  const [loading, setLoading] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const [inputType, setInputType] = useState(false)
  const [confirm_inputType, setconfirm_inputType] = useState(false)
  const [loginStatus, setLoginStatus] = useState(false)

  const navigate = useNavigate();
  const toast = useToast();
  const isLoggedIn = async () =>{
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/users/profile`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("quidsToken")}`,
        }
      });
      if(response){
        if(response.status == 200){
          navigate("/chat")
        }
      }
    } catch (error) {
      if(error.response.data.statusCode === 401){
        setLoginStatus(false)
      }
    }
  }
  useEffect(() => {
    isLoggedIn();
  }, [])
  
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const submitForm = (data) => {
    setLoading(true);
    data = { ...data, callbackUrl: currentUrl };
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/auth/signup`, data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
        }
      })
      .then((response) => {
        setLoading(false);
        toast({ title: "Registration successful", status: "success" });
        navigate("/signin");
      })
      .catch((error) => {
        setLoading(false);
        toast({
          title: error.response.data.message || "An Error Occured",
          status: "warning",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const withGoogle = () => {
    try {
      window.location.href = `${process.env.REACT_APP_BASE_URL}/auth/google`;
      // await axios.get(`${process.env.REACT_APP_BASE_URL}/auth/google`).then((res)=>{
      // }).catch((err)=>{
      // })
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <>
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="bg-white lg:w-9/12 w-full h-11/12 shadow-lg rounded-lg overflow-hidden flex flex-col lg:flex-row">
          {/* Left Section */}
          <div className="bg-green-900 p-8 flex items-center justify-center">
            <img
              src="/assets/images/crypto1.png"
              alt="VR Illustration"
              className="w-full max-w-sm h-[400px] md:h-full"
            />
          </div>

          {/* Right Section */}
          <div className="flex-1 p-8">
            <div>
              <h2 className="text-2xl font-bold text-green-900 mb-4">Create an Account</h2>
              <form className="space-y-4" onSubmit={handleSubmit(submitForm)}>
                <div>
                  <label className="block text-sm font-medium text-gray-700">First Name</label>
                  <div className='group flex items-center gap-1 p-2 border border-gray-300 rounded-md focus-within:border-[var(--primary-color)]'>
                    <i className="fa fa-user-circle text-2xl text-[var(--primary-color)]"></i>
                    <input
                      {...register("firstname", { required: "Firstname is required" })}
                      name='firstname'
                      type="text"
                      required
                      placeholder="First Name"
                      className="w-full px-4 py-2 border-none focus:outline-none"
                    />
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Email Address</label>
                  <div className='group flex items-center gap-1 p-2 border border-gray-300 rounded-md focus-within:border-[var(--primary-color)]'>
                    <i className="fa fa-user-circle text-2xl text-[var(--primary-color)]"></i>
                    <input
                      {...register("lastname", { required: "Email is required" })}
                      name='lastname'
                      type="text"
                      required
                      placeholder="Last Name"
                      className="w-full px-4 py-2 border-none focus:outline-none"
                    />
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Email Address</label>
                  <div className='group flex items-center gap-1 p-2 border border-gray-300 rounded-md focus-within:border-[var(--primary-color)]'>
                    <i className="fa fa-envelope text-2xl text-[var(--primary-color)]"></i>
                    <input
                      {...register("email", { required: "Email is required" })}
                      name='email'
                      type="email"
                      required
                      placeholder="Email Address"
                      className="w-full px-4 py-2 border-none focus:outline-none"
                    />
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Password</label>
                  <div className='group flex items-center gap-1 p-2 border border-gray-300 rounded-md focus-within:border-[var(--primary-color)]'>
                    <i className="fa fa-key text-2xl text-[var(--primary-color)]"></i>
                    <input
                      {...register("password", { required: "Password is required" })}
                      name='password'
                      type={inputType ? "text" : "password"}
                      required
                      placeholder="Password"
                      className="w-full px-4 py-2 border-none focus:outline-none"
                    />
                    <button onClick={() => setInputType(!inputType)} type='button'><i className={inputType ? "fa fa-eye" : "fa fa-eye-slash"}></i></button>
                  </div>
                </div>
                <p className="text-[12px] ">Minimum of 8 characters, must contain at least an Upper-Case
                  character, at least a number and special character</p>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Confirm Password</label>
                  <div className='group flex items-center gap-1 p-2 border border-gray-300 rounded-md focus-within:border-[var(--primary-color)]'>
                    <i className="fa fa-key text-2xl text-[var(--primary-color)]"></i>
                    <input
                      {...register("confirmPassword", { required: "Password does not match" })}
                      name='confirmPassword'
                      type={confirm_inputType ? "text" : "password"}
                      required
                      placeholder="Password"
                      className="w-full px-4 py-2 border-none focus:outline-none"
                    />
                    <button onClick={() => setconfirm_inputType(!confirm_inputType)} type='button'><i className={confirm_inputType ? "fa fa-eye" : "fa fa-eye-slash"}></i></button>
                  </div>
                </div>
                <div className="flex gap-2">
                  <input type="checkbox" required />
                  <span className="text-[14px]">I agree with Terms and Policy</span>
                </div>
                <button
                  type="submit"
                  disabled={loading}
                  className="w-full py-3 text-[18px] font-[700] bg-[var(--primary-color)] text-white rounded-md hover:bg-green-600"
                >
                  {loading ?
                    <i className="fa fa-spinner text-2xl text-white animate-spin"></i>
                    : "Sign In"
                  }
                </button>
              </form>
              <div className="mt-4">
                <button
                  onClick={withGoogle}
                  type="button"
                  className="w-full py-3 text-[18px] font-[700] border border-2 border-[var(--primary-color)] rounded-md flex items-center justify-center gap-2 hover:bg-gray-100"
                >
                  <img src="/assets/images/google.png" alt="Google" className="w-5 h-5" />
                  Sign up with Google
                </button>
              </div>
              <p className="mt-4 text-center text-sm">
                Already have an account?{' '}
                <Link to={"/signin"} className="text-green-500 font-[700] hover:underline">Log in</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>

  );
};

export default Signup;
