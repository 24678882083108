import axios from "axios";
import { useState, useEffect } from "react";

const AdminDashboard = () => {
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState("");
    const [loading, setloading] = useState(false)


    const fetchAllUsers = async () => {
        setloading(true)
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/users`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("quidsToken")}`,
                }
            });
            if (response) {
                setUsers(response.data.data);
                setloading(false)
            }
        } catch (error) {
            setloading(false)
        }
    }
    useEffect(() => {
        fetchAllUsers();
    }, []);

    const filteredUsers = users.filter(user =>
        user.profile.firstName.toLowerCase().includes(search.toLowerCase())
    );

    return (
        <div className="min-h-screen bg-[var(--bg-color)] text-white p-6">
            <div className="max-w-6xl mx-auto">
                <h1 className="text-3xl font-bold text-[#3EA842] mb-4">Admin Dashboard</h1>
                <input
                    type="text"
                    placeholder="Search users..."
                    className="w-full p-2 rounded-md text-black mb-4"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
                <div className="bg-white p-4 rounded-lg shadow-md">
                    <table className="w-full text-black">
                        <thead>
                            <tr className="bg-[#3EA842] text-white">
                                <th className="p-3 text-left">User</th>
                                <th className="p-3 text-left">Available Package</th>
                                <th className="p-3 text-left">Bonus Credit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users &&
                                users.map((user) => (
                                    <tr key={user.id} className="border-b border-gray-200">
                                        <td className="p-3">{user.profile.firstName} {user.profile.lastName}</td>
                                        <td className="p-3">{user.bonusCredit}C</td>
                                        <td className="p-3">{user.credit}C</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default AdminDashboard;
