import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const GoogleAuthError = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-red-50 text-red-700">
      <motion.div
        animate={{ scale: [1, 1.2, 1] }}
        transition={{ duration: 1, repeat: Infinity, ease: "easeInOut" }}
        className="mb-4"
      >
        <svg
          className="w-16 h-16 text-red-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2M15 9l-6 6M9 9l6 6"
          />
        </svg>
      </motion.div>

      {/* Error Message */}
      <h1 className="text-2xl font-bold">Authentication Failed</h1>
      <p className="text-center max-w-md mt-2">
        Oops! Something went wrong during authentication. Please try again.
      </p>

      <button
        className="mt-6 px-6 py-3 bg-red-600 text-white rounded-lg shadow-md hover:bg-red-700 transition"
        onClick={() => navigate("/signin")}
      >
        Retry
      </button>
    </div>
  );
};

export default GoogleAuthError;
