import { Box, HStack, Image, Text, useToast } from "@chakra-ui/react";
import AuthButton from "../components/AuthButton";
import AuthInput from "../components/AuthInput";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";

const ForgotPassword = () => {
  // const currentUrl = window.location.origin + "/reset-password";
  const currentUrl = "https://quidx.com/reset-password"
  const [err, setErr] = useState(null)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const onSubmitHandler = async (data) => {
    setLoading(true);
    data = { ...data, callbackUrl: currentUrl };

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/auth/forgetpasswordbegin`,
        data
      );
      setLoading(false);
      toast({
        title: "Success!",
        description: "Password reset email sent successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (err) {
      setErr(err.response.data.message);
      setLoading(false);
      toast({
        title: "Error",
        description: err.response.data.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>

      <div className="min-h-screen flex justify-center bg-gray-100">
        <div className="bg-white lg:w-9/12 w-full h-11/12 shadow-lg overflow-hidden flex flex-col lg:flex-row">
          {/* Left Section */}
          <div className="bg-green-900 p-8 flex items-center justify-center">
            <img
              src="/assets/images/forgot.png"
              alt="VR Illustration"
              className="w-full max-w-sm"
            />
          </div>

          {/* Right Section */}
          <div className="flex-1 p-8">
            <div>
              <h2 className="text-2xl font-bold text-green-900 mb-4">Forgot Password?</h2>
              <form className="space-y-4" onSubmit={handleSubmit(onSubmitHandler)}>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Enter the email address associated <br /> with your account</label>
                  <div className='group flex items-center gap-1 p-2 border border-gray-300 rounded-md focus-within:border-[var(--primary-color)]'>
                    <i className="fa fa-envelope text-2xl text-[var(--primary-color)]"></i>
                    <input
                      {...register("email", { required: "Email is required" })}
                      name='email'
                      type="email"
                      required
                      placeholder="Email Address"
                      className="w-full px-4 py-2 border-none focus:outline-none"
                    />
                  </div>
                  {errors.email && (
                      <Text color="red.500">{errors.email.message}</Text>
                    )}
                    {err && (
                      <Text color="red.500">{err}</Text>
                    )}
                </div>
                <button
                  type="submit"
                  disabled={loading}
                  className="w-full py-3 text-[18px] font-[700] bg-[var(--primary-color)] text-white rounded-md hover:bg-green-600"
                >
                  {loading ?
                    <i className="fa fa-spinner text-2xl text-white animate-spin"></i>
                    : "Continue"
                  }
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
    // <Box
    //   width="full"
    //   height={"100vh"}
    //   padding={"20px"}
    //   display={"flex"}
    //   justifyContent={"center"}
    //   alignItems={"center"}
    // >
    //   <HStack width={"full"} height={"95%"} gap={["0px", "0px", "51px"]}>
    //     <Box
    //       width={["0%", "50%", "50%"]}
    //       height={"100%"}
    //       borderRadius={"32px"}
    //       background="white"
    //       display={["none", "flex", "flex"]}
    //       justifyContent={"center"}
    //       alignItems={"center"}
    //       bgImage={"url(/assets/images/bg.png)"}
    //       backgroundRepeat={"no-repeat"}
    //       backgroundSize={"cover"}
    //       overflow={"hidden"}
    //     >
    //       <Image src="/assets/images/forgot.png" />
    //     </Box>
    //     <Box
    //       width={["full", "100%", "50%"]}
    //       flex={1}
    //       paddingRight={["0px", "0px", "190px"]}
    //       display={"flex"}
    //       flexDir={"column"}
    //     >
    //       <Text
    //         color={"#141414"}
    //         fontWeight={"700"}
    //         fontSize={["30px", "36px", "48px"]}
    //         textAlign={"left"}
    //         lineHeight={"normal"}
    //         marginBottom={"48px"}
    //       >
    //         Forgot Password?
    //       </Text>

    //       <Text
    //         marginBottom={"28px"}
    //         display={"flex"}
    //         alignItems={"center"}
    //         gap={"10px"}
    //         color={"#333"}
    //       >
    //         Enter the email address associated <br />
    //         with your account
    //       </Text>
    //       <form
    //         style={{ width: "100%" }}
    //         onSubmit={handleSubmit(onSubmitHandler)}
    //       >
    //         <AuthInput
    //           placeholder="Enter Email Address"
    //           register={register("email")}
    //           {...register("email", {
    //             required: "Email is required",
    //             pattern: {
    //               value: /^\S+@\S+$/i,
    //               message: "Enter a valid email address",
    //             },
    //           })}
    //         />
    //         {errors.email && (
    //           <Text color="red.500">{errors.email.message}</Text>
    //         )}
    //         <Box
    //           width="full"
    //           display={"flex"}
    //           flexDir={"column"}
    //           alignItems={"flex-start"}
    //           gap={"24px"}
    //           marginBottom={"12px"}
    //           marginTop={"50px"}
    //         >
    //           <AuthButton
    //             bg="#3EA842"
    //             color="white"
    //             isLoading={loading}
    //             type="submit"
    //           >
    //             Continue
    //           </AuthButton>
    //         </Box>
    //       </form>
    //     </Box>
    //   </HStack>
    // </Box>
  );
};

export default ForgotPassword;
