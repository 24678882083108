import React, { useEffect, useState } from "react";
import ThemeLayout from "../components/ThemeLayout";
import {
	Button,
	HStack,
	Input,
	Select,
	Text,
	VStack,
	Spinner,
	Slider,
	SliderMark,
	SliderTrack,
	SliderThumb,
	SliderFilledTrack,
	Box,
} from "@chakra-ui/react";
import SectionContainer from "../components/SectionContainer";
import axios from "axios";
import Header from "../components/Header";

const Calculator = () => {
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState("forex");
	const [rates, setRates] = useState([]);
	const [action, setAction] = useState("buy");
	const [price, setPrice] = useState("");
	const [openPrice, setOpenPrice] = useState("");
	const [pair, setPair] = useState("");
	const [closePrice, setClosePrice] = useState("");
	const [lot, setLot] = useState(1);
	const [profit, setProfit] = useState(0);
	const [margin, setMargin] = useState(0);
	const labelStyles = {
		mt: "2",
		ml: "-2.5",
		fontSize: "sm",
	};

	const fetchRates = () => {
		setLoading(true);
		axios
			.get(`${process.env.REACT_APP_BASE_URL}/rate/${page}`, {
				headers: {
					authorization: `Bearer ${localStorage.getItem("quidsToken")}`,
				},
			})
			.then((response) => {
				setRates(response?.data?.data);
				const tempOpenPrice = parseFloat(response.data.data[0].value).toFixed(
					2
				);
				const percent = 0.01 * tempOpenPrice;
				setPrice(tempOpenPrice);
				setOpenPrice(tempOpenPrice);
				setProfit(0);

				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
			});
	};
	const calculateProfit = (action, entry, exit, size) => {
		let profit;
		if (action === "buy") {
			if (page === "crypto") {
				profit = (exit - entry) * size;
			} else {
				profit = (exit - entry) * (size * 10000);
			}
		} else {
			if (page === "crypto") {
				profit = (entry - exit) * size;
			} else {
				profit = (entry - exit) * (size * 10000);
			}
		}

		if (page === "crypto") {
			setProfit(profit.toFixed(3));
		} else if (page === "forex") {
			setProfit(profit.toFixed(2));
		}
	};

	useEffect(() => {
		fetchRates();
	}, [page]);

	return (
		<ThemeLayout>
			<Header showLogo={true} />

			<SectionContainer>
				{" "}
				<VStack my={"2"} width={"full"} gap={"6"}>
					<HStack
						margin={"0 auto"}
						p={"6"}
						borderRadius={"16px"}
						bg={"rgba(211, 220, 209, 1)"}
						justifyContent={"space-between"}
						alignItems={["center"]}
						gap={"20px"}
						flexDir={[
							"column-reverse",
							"column-reverse",
							"column-reverse",
							"row",
						]}
						width={["100%", "100%", "100%", "80%"]}
					>
						<HStack spacing={"none"}>
							<Button
								bg={page === "forex" ? "rgba(62, 168, 66, 1)" : "none"}
								onClick={() => setPage("forex")}
								border={"1px solid rgba(62, 168, 66, 1)"}
								borderRadius={"2px"}
								_hover={{ bg: "rgba(62, 168, 66, 1)" }}
							>
								Forex Pairs
							</Button>
							<Button
								bg={page === "crypto" ? "rgba(62, 168, 66, 1)" : "none"}
								borderRadius={"2px"}
								border={"1px solid rgba(62, 168, 66, 1)"}
								onClick={() => setPage("crypto")}
							>
								Crypto Pairs
							</Button>
						</HStack>
						<Text
							fontSize={"20px"}
							fontWeight={"600"}
							color={"rgba(27, 75, 29, 1)"}
						>
							Calculate your profit on crypto pairs
						</Text>
					</HStack>

					<HStack
						width={["100%", "100%", "100%", "80%"]}
						margin={"0 auto"}
						alignItems={["flex-start", "flex-start", "flex-start", "center"]}
						gap={"20px"}
						flexDir={["column", "column", "column", "row"]}
					>
						<VStack
							width={"full"}
							bg={"rgba(27, 75, 29, 1)"}
							borderRadius={"16px"}
							py={"30px"}
							gap={"16px"}
							height={"900px"}
							overflowY={"scroll"}
						>
							<Text fontSize={"24px"} color={"#fff"} fontWeight={"600"}>
								Profit Calculator
							</Text>
							<VStack
								width={"full"}
								alignItems={"flex-start"}
								px={"30px"}
								borderRadius={"16px"}
							>
								<Text color={"#fff"}>
									{page === "forex" ? "CURRENCY" : "CRYPTO"} PAIR
								</Text>
								<Select
									onChange={(e) => {
										setPrice(0);
										setOpenPrice(e.target.value);
										setClosePrice(0);
										calculateProfit(action, 0, 0, lot);
									}}
									bg={"rgba(127, 153, 122, 1)"}
								>
									{rates !== null &&
										rates.map((rate, id) => {
											return (
												<option
													key={id}
													value={parseFloat(rate.value).toFixed(2)}
												>
													{rate.id}
													{rate.basePair}
												</option>
											);
										})}
								</Select>
							</VStack>
							<VStack
								width={"full"}
								alignItems={"flex-start"}
								px={"30px"}
								borderRadius={"16px"}
							>
								<Text color={"#fff"}>BUY OR SELL</Text>
								<Select
									value={action}
									onChange={(e) => {
										setAction(e.target.value);
										calculateProfit(e.target.value, openPrice, closePrice, lot);
									}}
									bg={"rgba(127, 153, 122, 1)"}
								>
									<option value={"buy"}>Buy</option>
									<option value={"sell"}>Sell</option>
								</Select>
							</VStack>
							<VStack
								width={"full"}
								alignItems={"flex-start"}
								px={"30px"}
								borderRadius={"16px"}
							>
								<Text color={"#fff"}>OPEN PRICE</Text>
								<Input
									value={openPrice}
									type="text"
									bg={"rgba(127, 153, 122, 1)"}
									placeholder="1.1"
									onChange={(e) => {
										setOpenPrice(e.target.value);
										calculateProfit(
											action,
											e.target.value,
											closePrice,
											page === "forex" ? lot : margin
										);
									}}
								/>
							</VStack>
							<VStack
								width={"full"}
								alignItems={"flex-start"}
								px={"30px"}
								borderRadius={"16px"}
							>
								<Text color={"#fff"}>CLOSE PRICE</Text>
								<Input
									value={closePrice}
									type="text"
									bg={"rgba(127, 153, 122, 1)"}
									placeholder="1.1"
									onChange={(e) => {
										setClosePrice(e.target.value);
										calculateProfit(
											action,
											openPrice,
											e.target.value,
											page === "forex" ? lot : margin
										);
									}}
								/>
							</VStack>
							{page === "forex" ? (
								<VStack
									width={"full"}
									alignItems={"flex-start"}
									px={"30px"}
									borderRadius={"16px"}
								>
									<Text color={"#fff"}>TRADE SIZE(LOTS)</Text>
									<Input
										value={lot}
										type="text"
										bg={"rgba(127, 153, 122, 1)"}
										placeholder="1"
										onChange={(e) => {
											setLot(e.target.value);
											calculateProfit(
												action,
												openPrice,
												closePrice,
												e.target.value
											);
										}}
									/>
								</VStack>
							) : (
								<VStack
									width={"full"}
									alignItems={"flex-start"}
									px={"30px"}
									borderRadius={"16px"}
								>
									<Text color={"#fff"}>Margin</Text>
									<Box p={4} pt={6} width={"full"}>
										<Slider
											aria-label="slider-ex-6"
											defaultValue={0}
											onChange={(val) => {
												setMargin(val);
												calculateProfit(action, openPrice, closePrice, val);
											}}
											color={"#fff"}
											fontWeight={"500"}
										>
											<SliderMark value={25} {...labelStyles}>
												25X
											</SliderMark>
											<SliderMark value={50} {...labelStyles}>
												50X
											</SliderMark>
											<SliderMark value={75} {...labelStyles}>
												75X
											</SliderMark>
											<SliderMark
												value={margin}
												textAlign="center"
												bg={"rgba(127, 153, 122, 1)"}
												color="white"
												mt="-10"
												ml="-5"
												w="12"
											>
												{margin}X
											</SliderMark>
											<SliderTrack>
												<SliderFilledTrack bg={"rgba(127, 153, 122, 1)"} />
											</SliderTrack>
											<SliderThumb />
										</Slider>
									</Box>
								</VStack>
							)}

							<HStack
								alignItems={"center"}
								bg={"rgba(127, 153, 122, 1)"}
								color={"rgba(27, 75, 29, 1)"}
								padding={"20px 30px"}
								borderRadius={"8px"}
							>
								<Text fontSize={"48px"} fontWeight={"600"}>
									${profit}
								</Text>
							</HStack>
							<Button bg={"#fff"} color={"rgba(27, 75, 29, 1)"}>
								Reset
							</Button>
						</VStack>

						<VStack
							width={"full"}
							bg={"rgba(211, 220, 209, 1)"}
							borderRadius={"16px"}
							py={"30px"}
							px={"30px"}
							gap={"16px"}
							height={"900px"}
							overflowY={"scroll"}
						>
							<Text
								fontSize={"24px"}
								color={"rgba(27, 75, 29, 1)"}
								fontWeight={"600"}
							>
								Main Currencies
							</Text>
							<HStack
								width={"full"}
								p={"14px 20px"}
								justifyContent={"space-between"}
							>
								<Text>INSTRUMENT</Text>
								<Text>RATE</Text>
							</HStack>
							{loading ? (
								<Spinner />
							) : rates !== null ? (
								rates.map((rate, id) => {
									return (
										<HStack
											width={"full"}
											p={"14px 20px"}
											justifyContent={"space-between"}
											borderRadius={"4px"}
											bg={"rgba(127, 153, 122, 1)"}
											color={"rgba(27, 75, 29, 1)"}
											key={id}
										>
											<Text>{rate.id}USD</Text>
											<Text>{parseFloat(rate.value).toFixed(2)}</Text>
										</HStack>
									);
								})
							) : (
								"Couldn't fetch rates"
							)}
							{}
						</VStack>
					</HStack>
				</VStack>
			</SectionContainer>
		</ThemeLayout>
	);
};

export default Calculator;
