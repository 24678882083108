import {
	Button,
	Container,
	HStack,
	Image,
	Box,
	VStack,
	Drawer,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	DrawerHeader,
	DrawerBody,
	Text,
	useDisclosure,
	IconButton,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import React, { useRef } from "react";
import { HamburgerIcon } from "@chakra-ui/icons";

const DashNav = ({showLogo, creditScore}) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	// const [creditScore, setCreditScore] = useState(null)

	const btnRef = useRef();
	const url = window.location.href;
	const param = url.split("/")[3];
	const navigate = useNavigate();
	return (
		<Box width={"full"} bg={"transparent"}>
			<Container maxWidth={"90%"} display={["none", "none", "block"]}>
				<HStack
					width={"full"}
					justifyContent={"space-between"}
					alignItems={"center"}
					py={"32px"}
				>
					<Image src="../assets/images/quidsailogo.png" width={"66px"} />

					<HStack alignItems={"center"} gap={"40px"}>
						<div className="flex bg-[#DAF1DB] rounded-[48px] gap-3 pr-2 items-center">
							<div className="bg-[#1B4B1D] text-white text-[20px] font-[700] rounded-full p-3 px-5">A</div>
							<div className="text-[18px] text-[#1B4B1D]">{creditScore}</div>
						</div>
						{/* <Link
							to={"/"}
							style={{
								color: "#141414",
								fontWeight: !param ? "600" : "",
								fontSize: "18px",
							}}
						>
							Home
						</Link>
						<Link
							to={"/chat"}
							style={{
								color: "#141414",
								fontWeight: param === "chat" ? "600" : "",
								fontSize: "18px",
							}}
						>
							Chat X
						</Link> */}

						<Button
							backgroundColor="#3EA842"
							color={"#fff"}
							_hover={{ bg: "#eee" }}
							fontSize={"15px"}
							onClick={() => {
								localStorage.removeItem("quidsToken");
								localStorage.removeItem("userId");
								navigate("/signin");
							}}
						>
							Logout
						</Button>
					</HStack>
				</HStack>
			</Container>

			{/* Mobile View */}
			<Container maxWidth={"full"} display={["block", "block", "none"]}>
				<HStack
					width={"full"}
					justifyContent={"space-between"}
					alignItems={"center"}
					pt={"5px"}
					pb={"12px"}
					borderBottom={"1px solid #E6E5E5"}
				>
					{showLogo && (
						<Image src="/assets/images/quidsailogo.png" width={"66px"} />
					)}

					<IconButton
						ref={btnRef}
						onClick={onOpen}
						display="flex"
						alignSelf={"flex-end"}
						bg="transparent"
					>
						<HamburgerIcon />
					</IconButton>
					<Drawer
						isOpen={isOpen}
						placement={"right"}
						onClose={onClose}
						finalFocusRef={btnRef}
					>
						<DrawerOverlay />
						<DrawerContent alignItems="center">
							<DrawerCloseButton alignSelf="end" mx={15} my={15} />
							<DrawerHeader my={15}>
								<Text as="p"> {"QuidX"} </Text>
							</DrawerHeader>
							<DrawerBody>
								<VStack alignItems={"center"} gap={"40px"}>
									<Link
										to={"/"}
										style={{
											color: "#141414",
											fontWeight: !param ? "600" : "",
											fontSize: "15px",
										}}
									>
										Home
									</Link>
									<Link
										to={"/chat"}
										style={{
											color: "#141414",
											fontWeight: param === "chat" ? "600" : "",
											fontSize: "15px",
										}}
									>
										Chat X
									</Link>
									<Link
										to={"/pricing"}
										style={{
											color: "#141414",
											fontWeight: param === "pricing" ? "600" : "",
											fontSize: "15px",
										}}
									>
										Journal
									</Link>

									<Link to="/signup">
										<Button
											backgroundColor="#3EA842"
											color={"#fff"}
											_hover={{ bg: "#eee" }}
											fontSize={"15px"}
										>
											Logout
										</Button>
										{/* <CTAButton text={"Sign up"} /> */}
									</Link>
								</VStack>
							</DrawerBody>
						</DrawerContent>
					</Drawer>
				</HStack>
			</Container>
		</Box>
	);
};

export default DashNav;
